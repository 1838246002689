//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "ContactUs",
  props: {
    showContactUs: {
      type: Boolean,
      required: true,
    }
  },
  data() {
    return {
      rules: [v => !!v || 'Field is required'],
      contactUs: {
        FirstName: null,
        LastName: null,
        Address: null,
        City: null,
        State: null,
        ZipCode: null,
        Email: null,
        Message: null,
      },
      emailTo: null,
      isValid: null,
    }
  },
  mounted() {
    this.getContactUsContent()
  },
  methods: {
    async getContactUsContent() {
      const responseContactUs = await this.$axios.get(`/contents/contact-us`)
      if (responseContactUs &&
          responseContactUs.data &&
          responseContactUs.data.details &&
          responseContactUs.data.details.sections
      ) {
        const [contact] = responseContactUs.data.details.sections
        if (contact && contact.email &&
            contact.email.fieldValue &&
            contact.email.fieldValue.value
        ) {
          this.emailTo = contact.email.fieldValue.value.trim()
        }
      }
    },
    reset() {
      this.contactUs = {
        FirstName: null,
        LastName: null,
        Address: null,
        City: null,
        State: null,
        ZipCode: null,
        Email: null,
        Message: null,
      }
      this.isValid = null
    },
    close() {
      this.reset()
      this.$emit('close')
    },
    async sendEmail() {
      if (this.$refs.contactForm && this.$refs.contactForm.validate()) {
        const response = await this.$axios.$post(
            `/contactus/send-contact-us-email/${this.emailTo}`,
            this.contactUs
        )
        if (response.result === 0 && !response.errorMessage) {
          this.close()
          this.$toast.success("Thank you for contacting us. Your message is sent.")
        }else{
          //error
        }
      }
    },
  }
}
