export const productCategoryCodes = {
  Collection: 'ShopCollection',
  Category: 'Category',
  Gemstone: 'GEMSTONEF',
  Bridal: 'VLORABRIDAL',
}
export const productSubCategoryCodes = {
  BanglesBracelets: 'BANGLES,BRACELETS',
  Earrings: 'Earrings',
  NecklacesPendants: 'Necklaces,Pendants',
  Rings: 'Rings',
  EngagementRings: 'ENGAGEMENTRING',
  WeddingBands: 'WEDDINGBAND',
}

export const engagementRingsProductCategoryCodes = {
  Classic: 'CLASSIC',
  Halo: 'HALO',
  Solitaire: 'SOLITAIRE',
  ThreeStone: 'THREESTONE',
  TwoStone: 'TWOSTONE',
}

export const weddingBandsProductCategoryCodes = {
  StackableBands: 'STACKABLEBAND',
  MatchingBands: 'MATCHINGBAND',
  DiamondBands: 'DIAMONDBAND',
  ColorBands: 'COLORBAND',
}
