import {
  productSubCategoryCodes
} from "@/frontend/enums/productCategoryCodes";
export const productCategoriesMixin = {
  data: () => {
    return {
      productCategories: [],
      engagementRings: [],
      weddingBands: [],
      Categories: [
        {
          name: 'Bangle & Bracelets',
          code: productSubCategoryCodes.BanglesBracelets,
        },
        {
          name: 'Earrings',
          code: productSubCategoryCodes.Earrings,
        },
        {
          name: 'Necklace & Pendants',
          code: productSubCategoryCodes.NecklacesPendants,
        },
        {
          name: 'Rings',
          code: productSubCategoryCodes.Rings,
        },
      ],
    }
  },
  methods: {
    getChildProductCategories(parentID) {
      return this.productCategories
          .filter((category) => category.parentID === parentID)
          .map((childCategory) => {
            return {
              id: childCategory.id,
              name: childCategory.name,
              code: childCategory.code,
              option2: childCategory.option2,
            }
          })
    },
    async fetchAllCategories() {
      this.productCategories = await this.$axios.$get('/ProductCategories', {
        params: {
          getOnlyParent: false,
        },
      })
    },
  },
  async mounted() {
    if (this.productCategories && this.productCategories.length > 0) return
    await this.fetchAllCategories()
    this.productCategories.forEach((category) => {
      if (category.code === productSubCategoryCodes.EngagementRings) {
        this.engagementRings = this.getChildProductCategories(category.id)
      }
      if (category.code === productSubCategoryCodes.WeddingBands) {
        this.weddingBands = this.getChildProductCategories(category.id)
      }
    })
  },
}
